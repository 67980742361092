<template>
  <div v-if="young.id" class="user-profile-sidebar" :class="{ 'show': shallShowYoungDetailsSidebar }">
    <young-details-header :young="young" :is-modifying="isModifying" @hide-sidebar="hideSideBar()" />
    <!-- Details -->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="user-profile-sidebar-area scroll-area">
      <b-button variant="outline-primary" class="mr-1 mb-2 w-100" @click="openModal()">
        <span class="ml-25 align-middle">
          Gestion des données
        </span>
      </b-button>
      <young-details-list v-if="!isModifying" :young="young" />
      <young-form v-else :young="young" @update-action="setIsModifying" />
      <div v-if="young.nameSakeIds && young.nameSakeIds.length > 0" class="name-sakes">
        <h6 class="section-label mb-1 mt-3">
          Homonymes
        </h6>
        <ul class="list-unstyled m-0">
          <li v-for="nameSakeId in young.nameSakeIds" :key="nameSakeId" class="mb-1">
            <a href="#" @click.prevent.stop="$emit('open-chat', nameSakeId)">
              Jeune {{ nameSakeId }}
            </a>
          </li>
        </ul>
      </div>
      <b-button v-if="!isModifying && canModify" variant="outline-primary" @click="setIsModifying">Modifier la
        fiche</b-button>
      <div v-if="young.users && young.users.length > 0 && young.users[0] !== null" class="users">
        <h6 class="section-label mb-1 mt-3">
          Intervenant(s)
        </h6>
        <ul class="list-unstyled m-0">
          <li v-for="user in young.users" :key="user" class="mb-1">
            {{ user }}
          </li>
        </ul>
      </div>
    </vue-perfect-scrollbar>
    <b-modal ref="modal" hide-header hide-footer title="Gestion des données" class="data-modal">
      <h2 class="text-primary text-center mb-2">
        Gestion des données
      </h2>
      <p v-if="modalMessage" class="text-center mb-2 text-success">
        {{ modalText }} effectué
      </p>
      <span v-if="isExtractDatasEvent" class="data-modal__subtext">Dernière extraction faite le : {{ formatDate(extractDatasEvent.date) }} par {{ extractDatasEvent.userFirstName }} {{ extractDatasEvent.userLastName }}</span>
      <span v-else class="data-modal__subtext">Données jamais extraites</span>
      <b-button variant="outline-primary" class="mr-1 mb-2 w-100 data-modal__button" @click="extractData">
        <span class="ml-25 align-middle">
          Extraire le journal d'interactions du jeune
        </span>
      </b-button>
      <span v-if="isTransferDataEvent" class="data-modal__subtext">Dernière données transmise le : {{ formatDate(transferDataEvent.date) }}  par {{ transferDataEvent.userFirstName }} {{ transferDataEvent.userLastName }}</span>
      <span v-else class="data-modal__subtext">Données jamais transmises</span>
      <b-button variant="outline-primary" class="mr-1 mb-2 w-100 data-modal__button" @click="transferData">
        <span class="ml-25 align-middle">
          Transmettre les données
        </span>
      </b-button>
      <span v-if="isYoungAnonymize" class="data-modal__subtext">Anonymisation faite le : {{ formatDate(anonymizeEvent.date) }} par {{ anonymizeEvent.userFirstName }} {{ anonymizeEvent.userLastName }}</span>
      <span v-else class="data-modal__subtext">Jeune non anonymisé</span>
      <b-button v-if="isYoungAnonymize" disabled class="mr-1 mb-2 w-100 data-modal__button">
        <span class="ml-25 align-middle">
          Anonymiser le jeune
        </span>
      </b-button>
      <b-button v-else variant="outline-primary" class="mr-1 mb-2 w-100 data-modal__button" @click="anonymize">
        <span class="ml-25 align-middle">
          Anonymiser le jeune
        </span>
      </b-button>
      <p v-if="openConfirmationAnonymization" class="text-center text-danger">
        Voulez-vous vraiment anonymisé les données ?
      </p>
      <div v-if="openConfirmationAnonymization" class="data-modal__delete-confirm ">
        <b-button variant="danger" @click="cancelAnonymization">
          Annuler
        </b-button>
        <b-button variant="primary" @click="confirmAnonymization">
          Confirmer
        </b-button>
      </div>
      <b-button variant="outline-primary" class="mr-1 mb-2 w-100 data-modal__button data-modal__button-remove" @click="deleteData">
        <span class="ml-25 align-middle">
          Supprimer les données
        </span>
      </b-button>
      <p v-if="openConfirmationDeletion" class="text-center text-danger">
        Voulez-vous vraiment supprimer les données ?
      </p>
      <div v-if="openConfirmationDeletion" class="data-modal__delete-confirm ">
        <b-button variant="danger" @click="cancelDeletion">
          Annuler
        </b-button>
        <b-button variant="primary" @click="confirmDeletion">
          Confirmer
        </b-button>
      </div>
      <div class="d-flex justify-content-end">
        <b-button variant="danger" class="mr-2 data-modal__back-button" @click="closeModal()">
          Annuler
        </b-button>
      </div>
    </b-modal>
    <b-modal ref="modalDeletion" hide-header hide-footer title="Gestion des données" class="data-modal">
      <h2 class="text-primary text-center mb-2">
        Confirmation de Suppression de Données
      </h2>
      <p class="text-center mb-2 text-danger">
        Avertissement : Suppression de Données Définitive
      </p>
      <p class="text-center ">
        Cher(e) utilisateur(trice),
        Vous êtes sur le point d'effectuer une opération de suppression de données qui est irréversible. Veuillez prendre un moment pour lire attentivement les informations suivantes :
      </p>
      <ul>
        <li>
          <span class="font-weight-bold">Irréversibilité :</span> Cette action de suppression est permanente. Toutes les données sélectionnées seront définitivement effacées de notre système et ne pourront pas être récupérées.
        </li>
        <li>
          <span class="font-weight-bold">Aucune Restauration Possible :</span> Après la suppression, il ne sera pas possible de restaurer ou de récupérer les données supprimées. Soyez certain(e) que vous n'avez plus besoin de ces données ou que vous disposez d'une copie de sauvegarde appropriée, le cas échéant.
        </li>
      </ul>
      <p class="text-center">
        Si vous comprenez les conséquences de cette suppression définitive et que vous souhaitez la poursuivre, cliquez sur le bouton "Confirmer la Suppression" ci-dessous.
      </p>
      <div class="d-flex justify-content-end">
        <b-button variant="danger" class="mr-2 data-modal__back-button" @click="closeModalConfirmation()">
          Annuler
        </b-button>
        <b-button variant="primary" class="mr-2 data-modal__back-button" @click="finalConfirmDeletion()">
          Confirmer la suppression
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
import { formatDate, formatPhoneNumber } from '@core/utils/filter'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'
import departements from '@/assets/departments.json'
import store from '@/store'
import YoungDetailsList from './YoungDetailsList.vue'
import YoungForm from './YoungForm.vue'
import YoungDetailsHeader from './YoungDetailsHeader.vue'

export default {
  components: {
    BButton,
    VuePerfectScrollbar,
    YoungDetailsList,
    YoungForm,
    YoungDetailsHeader,
    BModal,
  },
  props: {
    shallShowYoungDetailsSidebar: {
      type: Boolean,
      required: true,
    },
    young: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const modalMessage = ref(false)
    const modalText = ref('')
    const openConfirmationDeletion = ref(false)
    const openConfirmationAnonymization = ref(false)
    const modal = ref(null)
    const modalDeletion = ref(null)
    const isYoungAnonymize = ref(false)
    const isExtractDatasEvent = ref(false)
    const isTransferDataEvent = ref(false)

    const anonymizeEvent = computed(() => store.getters['events/eventYoungAnonymize'])
    const extractDatasEvent = computed(() => store.getters['events/eventYoungExtractDatas'])
    const transferDataEvent = computed(() => store.getters['events/eventYoungTransferData'])
    const excelDatas = computed(() => store.getters['youngs/excelDatas'])
    const zipDatas = computed(() => store.getters['youngs/zipDatas'])

    const dispatchEvent = async (event, younId) => {
      try {
        await store.dispatch(`events/${event}`, { youngId: younId })
        return true
      } catch (error) {
        if (error.response.status === 404) {
          return false
        }
          throw error
      }
    }

    const openModal = async () => {
      isYoungAnonymize.value = await dispatchEvent('fetchEventYoungAnonymize', props.young.id)
      isExtractDatasEvent.value = await dispatchEvent('fetchEventYoungExtractDatas', props.young.id)
      isTransferDataEvent.value = await dispatchEvent('fetchEventYoungTransferData', props.young.id)

      modal.value.show()
    }

    const closeModal = () => {
      modal.value.hide()
      modalMessage.value = false
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const getFileLink = (file, fileName) => {
      const url = window.URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    const extractData = async () => {
      await store.dispatch('youngs/fetchExcelDatas', props.young.id)
      const file = excelDatas.value
      const fileName = `young_datas_${props.young.lastName}_${props.young.firstName}.xlsx`
      getFileLink(file, fileName)
      await store.dispatch('events/fetchEventYoungExtractDatas', { youngId: props.young.id })
      isExtractDatasEvent.value = true
    }

    const transferData = async () => {
      await store.dispatch('youngs/fetchZipDatas', props.young.id)
      const file = zipDatas.value
      const fileName = `young_datas_${props.young.lastName}_${props.young.firstName}.zip`
      getFileLink(file, fileName)
      await store.dispatch('events/fetchEventYoungTransferData', { youngId: props.young.id })
      isTransferDataEvent.value = true
    }

    const anonymize = async () => {
      openConfirmationAnonymization.value = true
    }

    const confirmAnonymization = async () => {
      modalMessage.value = false
      modalText.value = 'Anonymisation des données'
      await store.dispatch('users/anonymizeYoung', props.young.id)
      isYoungAnonymize.value = true
      await store.dispatch('events/fetchEventYoungAnonymize', { youngId: props.young.id })
      modalMessage.value = true
      openConfirmationAnonymization.value = false
    }

    const cancelAnonymization = () => {
      openConfirmationAnonymization.value = false
    }

    const deleteData = () => {
      openConfirmationDeletion.value = true
    }

    const cancelDeletion = () => {
      openConfirmationDeletion.value = false
    }

    const confirmDeletion = async () => {
      modalMessage.value = false
      openConfirmationDeletion.value = false
      modalDeletion.value.show()
    }

    const finalConfirmDeletion = async () => {
      modalMessage.value = false
      await store.dispatch('youngs/deleteYoung', props.young.id)
      openConfirmationDeletion.value = false
      modalDeletion.value.hide()
      modal.value.hide()
    }

    const closeModalConfirmation = () => {
      modalDeletion.value.hide()
    }

    return {
      formatDate,
      formatPhoneNumber,
      perfectScrollbarSettings,
      extractData,
      transferData,
      isYoungAnonymize,
      anonymize,
      anonymizeEvent,
      isExtractDatasEvent,
      extractDatasEvent,
      isTransferDataEvent,
      transferDataEvent,
      openConfirmationAnonymization,
      cancelAnonymization,
      confirmAnonymization,
      deleteData,
      openConfirmationDeletion,
      cancelDeletion,
      confirmDeletion,
      finalConfirmDeletion,
      closeModalConfirmation,
      modalDeletion,
      modalMessage,
      modalText,
      modal,
      openModal,
      closeModal,
    }
  },
  data() {
    return {
      departements,
      isModifying: false,
    }
  },
  computed: {
    ...mapGetters({
      taxonomies: 'initialData/getTaxonomies',
      user: 'oidc/oidcUser',
    }),
    console() {
      return console
    },
    canModify() {
      const authorizedUsers = this.young.users.map(usernName => usernName.toUpperCase())
      const name = this.user.name.toUpperCase()

      return authorizedUsers.includes(name)
    },

  },
  watch: {
    young: {
      handler() {
        this.isModifying = false
      },
      deep: true,
    },
    shallShowYoungDetailsSidebar: {
      handler() {
        if (!this.shallShowYoungDetailsSidebar) {
          this.isModifying = false
        }
      },
    },
  },
  methods: {
    setIsModifying() {
      this.isModifying = !this.isModifying
    },
    getTaxonomyLabel(taxonomy, propertyValue) {
      return this.taxonomies[taxonomy].find(entry => entry.id === propertyValue)?.label || ''
    },
    hideSideBar() {
      this.$emit('update:shall-show-young-details-sidebar', false)
      this.isModifying = false
    },
  },
}
</script>

<style lang="scss" scoped>
.data-modal {
  &__button {
    margin-top: 4px;
  }
  &__button-remove {
    margin-top: 1rem;
  }
  &__back-button {
    margin-top: 1rem;
  }
  &__delete-confirm {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
}
</style>

import { render, staticRenderFns } from "./YoungDetails.vue?vue&type=template&id=b737f756&scoped=true&"
import script from "./YoungDetails.vue?vue&type=script&lang=js&"
export * from "./YoungDetails.vue?vue&type=script&lang=js&"
import style0 from "./YoungDetails.vue?vue&type=style&index=0&id=b737f756&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b737f756",
  null
  
)

export default component.exports